import * as React from 'react'
import { graphql } from 'gatsby'
import { PressReleasePageTemplate } from './press-release-page-template'

const PressReleasePage = ({ data, pageContext }) => {
  const page = {
    pathname: pageContext.location.pathname,
    banner: data.mdx.frontmatter.banner,
    title: data.mdx.frontmatter.title,
    subtitle: data.mdx.frontmatter.subtitle,
    body: data.mdx.body,
  }

  return (
    <PressReleasePageTemplate
      page={page}
      name={pageContext.name}
      pathname={pageContext.location.pathname}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        banner
        title
        subtitle
      }
      body
    }
  }
`

export default PressReleasePage
