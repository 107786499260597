import React from 'react'
import {
  BasicBanner,
  Container,
  Section,
  Seo,
} from '@components'
import { MDXRenderer } from "../../components/core/mdx";
import { PageLayout } from '../../components/site/layout/page-layout'

const PressReleasePageTemplate = ({ page, name, pathname }) => {
  return (
    <PageLayout pathname={pathname}>
      <BasicBanner title={page.banner} />

      <Section className="py-2 md:py-4 lg:8">
        <Container>
          <h2 className="text-center">{page.title}</h2>
          <p className="mx-8 italic text-center">{page.subtitle}</p>
          <MDXRenderer>{page.body}</MDXRenderer>
        </Container>
      </Section>
    </PageLayout>
  )
}

export { PressReleasePageTemplate }
